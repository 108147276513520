import Cookies from 'universal-cookie'
import fetch from 'isomorphic-fetch'
import { API_CALL, INTERNAL_ERROR, LOADING_START, LOADING_END, DISPLAY_NOTIFICATION, LOGOUT, ERROR_REPORT } from 'actions'

const cookies = new Cookies()

const checkResponse = (payload, request, dispatch, onfailure) => (res) =>
    res.text().then(text => {
        let json = null
        try {
            json = JSON.parse(text)
        } catch (error) {
            if (res.status < 500) {
                return Promise.reject({
                    message: "une erreur inattendue s'est produite",
                    status: res.status,
                })
            }
            payload = {
                ...payload,
                ...request,
                status: res.status,
                message: res.statusText,
                text,
            }
            const rejection = new Error("cannot parse response JSON")
            rejection.details = payload
            return Promise.reject(rejection)
        }

        if (res.ok) {
            return Promise.resolve(json)
        } else {
            return Promise.reject({
                ...json,
                status: res.status,
            })
        }
    }
)

const doCallback = (cb, dispatch, payload, params, action) => {
    if (!cb) {
        cb = () => null
    }
    switch(cb.length) {
        case  0:
        case  1:
            dispatch(action)
            cb({ dispatch, payload, params })
            break
        case  2:
            cb({ dispatch, payload, params }, () =>
                dispatch(action)
            )
            break
        default:
            console.error('incorrect callback')
            break
    }
}

export const apiMiddleware = store => next => action => {
    if (!action[API_CALL])
        return next(action)

    !!action[API_CALL].request.loading && next({type: LOADING_START})
    next({type: `${action.type}_REQUEST`})

    let { request, onsuccess, onfailure } = action[API_CALL]
    let { url, headers, method, body } = request
    const credentials = 'include'
    if (!headers) {
        headers = {}
    }
    if (!method) {
        method = body ? 'POST' : 'GET'
    }
    if (method === 'POST') {
        if (!headers['X-CSRFToken']) {
            headers['X-CSRFToken'] = cookies.get('csrftoken')
        }
    }
    if (!headers['Content-Type']) {
        headers['Content-Type'] = 'application/json;charset=UTF-8'
    }
    if (!headers['Accept']) {
        headers['Accept'] = 'application/json'
    }
    if (typeof(body) === 'object')
        body = JSON.stringify(body)
    url = ((process.env.REACT_APP_API_ROOT?process.env.REACT_APP_API_ROOT.replace('{HOST}', window.location.hostname):false) || window.location.origin + "/api") + url
    request = {...request, url, headers, method, body, credentials}
    const payload = {
        method: request.method,
        url: request.url,
    }
    return fetch(request.url, request)
    .then(checkResponse(payload, request, next, onfailure))
    .catch(except => {
        !!action[API_CALL].request.loading && next({type: LOADING_END})
        if (except instanceof Error) {
            if (except.details === undefined) {
                except.details = payload
            }
            next({
                type: `${action.type}_FAILURE`,
                error: except,
            })
            if (action.type !== ERROR_REPORT.action) {
                next({
                    type: INTERNAL_ERROR,
                    payload: except,
                })
            }
        } else {
            try {
                if (except.status === 403) {
                    next({
                        type: LOGOUT.REQUEST,
                    })
                    next({
                        type: DISPLAY_NOTIFICATION,
                        message: "Session expirée, veuillez vous réidentifier",
                        severity: "error",
                    })
                }
                const nextAction = {
                    type: `${action.type}_FAILURE`,
                    error: except,
                }
                if (typeof(action.params) !== 'undefined') {
                    nextAction.params = action.params
                }
                doCallback(onfailure, store.dispatch, except, action.params, nextAction)
            } catch (except) {
                console.error('exception occurred while dispatching ' +
                    `'${action.type}_FAILURE`, except)
                return Promise.reject(except)
            }
            const rejection = new Error("fetching " + method + " " + url)
            rejection.payload = except
            except = rejection
        }
        return Promise.reject(except)
    }).then(payload => {
        !!action[API_CALL].request.loading && next({type: LOADING_END})
        try {
            const nextAction = {
                type: `${action.type}_SUCCESS`,
                payload,
            }
            if (typeof(action.params) !== 'undefined') {
                nextAction.params = action.params
            }
            doCallback(onsuccess, store.dispatch, payload, action.params, nextAction)
        } catch (except) {
            console.error('exception occurred while dispatching ' +
                `${action.type}_SUCCESS`, except)
            return Promise.reject(except)
        }
        return Promise.resolve(payload)
    }).catch(error => Promise.resolve(error))
}
