import React from 'react'
import { Dialog, FlatButton, CssBaseline, withTheme, LinearProgress, withStyles } from 'components'
import * as login from './login'
import * as containers from 'containers'
import { connect } from 'react-redux'
import StackTrace from 'stacktrace-js'
import { Route, Switch, withRouter } from 'react-router-dom'
import { LOGIN, LOGOUT, DISPLAY_CHANGE, ACCOUNT_UPDATE, API_CALL, ERROR_REPORT, INTERNAL_ERROR, QUOTA_REQUEST, MAIL_UPDATE, MAIL_ADD, MAIL_REMOVE, PAYMENT_CONFIRM, REDEEM, CART_ADD, CART_UPDATE } from 'actions'
import Drawer from './drawer'
import AppBar from './bar'
import Home from './home'
import { reducer as searchReducer } from './search'
import { reducer as cartReducer, CartRoute } from './cart'
import css from './app.module.css'

const NotFound = ({ params, onClick, message, history }) =>
    <Dialog open={true} title={'Oooops !'} actions={[
            <FlatButton
                label={'Précédent'}
                primary={true}
                onClick={() => history.go(-1)} />
        ]}>
        {typeof(params) === 'undefined' ?
            message :
            `${window.location.origin}/${params.splat} n'existe pas !`}
    </Dialog>

const ErrorDialog = connect(
    state => ({report: state.app.report}),
)(withStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[200],
    },
    bar: {
        backgroundColor: theme.palette.grey[400],
    },
}))(withTheme(withRouter(({ history, theme, report, classes }) =>
    <Dialog
        title="Ooops !"
        open
        actions={
            <FlatButton
                label="Recharger la page d'accueil"
                primary
                onClick={() =>
                    window.location.reload()
                } />
        }>
        <div style={{textAlign: 'center'}}>
            <b style={{color: theme.palette.error.main}}>
                Quelque chose s'est mal passé sur cette partie de l'application.
            </b>
            <br/>
            <br/>
            <span style={{color: theme.palette.info.main}}>
                N'hésitez pas à contacter <a href="mailto:support@soeasyto.com">support@soeasyto.com</a> pour plus d'information.
                <br/>
                Soyez certain que l'équipe soeasyto met tout en oeuvre pour résoudre votre problème.
            </span>
        </div>
        {!!report ? !!report.error &&
            <div style={{
                    fontSize: '0.75rem',
                    color: theme.palette.text.disabled,
                    textAlign: 'center',
                    marginTop: theme.spacing(3),
                    fontStyle: 'italic',
                }}>
                Le problème a été reporté ({report.error}#{report.report}).
            </div> :
            <LinearProgress
                classes={classes} />}
    </Dialog>
))))

const initialState = {
    domains: [],
    user: null,
    display: null,
    report: null,
    error: null,
    version: null,
    keys: null,
    contacts: null,
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case DISPLAY_CHANGE:
            return {
                ...state,
                display: action.display,
            }
        case ACCOUNT_UPDATE.SUCCESS:
        case QUOTA_REQUEST.SUCCESS:
        case MAIL_ADD.SUCCESS:
        case MAIL_UPDATE.SUCCESS:
        case MAIL_REMOVE.SUCCESS:
        case PAYMENT_CONFIRM.SUCCESS:
        case CART_ADD.SUCCESS:
        case CART_UPDATE.SUCCESS:
        case REDEEM.SUCCESS:
            if (typeof(action.payload.account) !== 'undefined') {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        ...action.payload.account,
                    }
                }
            } else {
                return state
            }
        case LOGIN.FAILURE:
            return {
                ...initialState,
                display: state.display,
                version: action.error.version,
                keys: action.error.keys,
                contacts: action.error.contacts,
            }
        case LOGIN.SUCCESS:
            return {
                ...state,
                domains: action.payload.domains,
                user: action.payload.user,
                version: action.payload.version,
                keys: action.payload.keys,
                contacts: action.payload.contacts,
            }
        case LOGOUT.REQUEST:
            return {
                ...initialState,
                display: state.display,
                version: state.version,
                keys: state.keys,
                contacts: state.contacts,
            }
        case ERROR_REPORT.SUCCESS:
            return {
                ...initialState,
                display: state.display,
                version: state.version,
                keys: state.keys,
                contacts: state.contacts,
                report: action.payload,
            }
        case ERROR_REPORT.FAILURE:
            return {
                ...initialState,
                version: state.version,
                keys: state.keys,
                contacts: state.contacts,
                display: state.display,
                report: {},
            }
        case INTERNAL_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

const appReducer = {
    app: reducer,
    login: login.reducer,
    search: searchReducer,
    cart: cartReducer,
}

export { appReducer as reducer }

class App extends React.Component {
    state = { menuOpened: false, checking: true, error: false }

    componentDidMount() {
        this.props.checkSession(
            this.props.history,
            this.props.location,
            () => this.setState({ checking: false }),
        )
    }

    static reload(newVersion, localVersion) {
        console.info(
            'detected version change from',
            newVersion,
            'to',
            localVersion + ',',
            'reloading...'
        )
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(names => {
                Promise.all(names.map(name => caches.delete(name))).then(
                    window.location.reload(true)
                )
            })
        } else {
            // no Service worker cache
            window.location.reload(true)
        }
    }

    static getDerivedStateFromError(error) {
        return { error: true }
    }

    componentDidCatch(error, info) {
        const href = window.location.href
        const version = process.env.REACT_APP_VERSION || ''
        StackTrace.fromError(error).then(
            stack =>
                this.props.sendError(
                    `${error.toString()}\n  at ${stack[0].functionName} (${stack[0].fileName}:${stack[0].lineNumber}:${stack[0].columnNumber})\n${info.componentStack.trim()}`,
                    href,
                    version,
                )
        ).catch(err => console.error(err))
        this.props.history.replace('/')
    }

    componentDidUpdate(props, state) {
        if (this.state.menuOpened &&
            (this.props.display !== props.display ||
              !this.props.user)
        ) {
            this.setState({menuOpened: false})
        }
        if (state.checking && !this.state.checking) {
            // don't try to check version when there is no local storage available
            if (!!localStorage) {
                // there is a version to check
                if (!!this.props.version) {
                    const newVersion = this.props.version
                    const checkedVersion = localStorage.getItem('version')
                    if (newVersion !== checkedVersion) {
                        // a new version is available on the server
                        const localVersion = process.env.REACT_APP_VERSION
                        // store the checked version to avoid multiple reloads
                        localStorage.setItem('version', newVersion)
                        // check that it matches local bundle version
                        if (typeof(localVersion) !== 'undefined'
                            && !!localVersion
                            && localVersion !== newVersion) {
                            // local bundle version does not match, reload
                            App.reload(newVersion, localVersion)
                            return
                        }
                    }
                }
            }
            const loadingScreen = document.getElementById('loading-screen')
            loadingScreen.style.opacity = 0
            this.timeout = setTimeout(() =>
                loadingScreen.style.display = 'none', 200
            )
        }

        if (!props.error && !!this.props.error) {
            const href = window.location.href
            const version = process.env.REACT_APP_VERSION || ''
            const error = this.props.error
            this.setState({ checking:false, error: true })
            const loadingScreen = document.getElementById('loading-screen')
            loadingScreen.style.opacity = 0
            this.timeout = setTimeout(() =>
                loadingScreen.style.display = 'none', 200
            )
            StackTrace.fromError(error).then(
                stack =>
                    this.props.sendError(
                        stack.reduce((msg, frame) =>
                            `${msg}\n  at ${frame.functionName} (${frame.fileName}:${frame.lineNumber}:${frame.columnNumber})`,
                            error.toString(),
                        ),
                        Object.keys(error.details).reduce(
                                (msg, key) =>
                                    `${msg}\n    ${key}: ${
                                        typeof(error.details[key]) === 'string' ?
                                        error.details[key] :
                                        JSON.stringify(error.details[key])
                                    },`,
                                '',
                        ),
                        href,
                        version,
                    )
            ).catch(
                err => console.error(err)
            )
            this.props.history.replace('/')
        }
    }

    componentWillUnmount() {
        this.timeout && clearTimeout(this.timeout)
    }

    render() {
        const {
            onLogout, location,
            match, history,
        } = this.props
        const { checking, menuOpened, error } = this.state
        return (
            <React.Fragment>
                <CssBaseline />
                {checking ? null : !error ?
                    <div id={css.app}>
                        <Switch>
                            {CartRoute()}
                            <Route path={["/:domain/:app", "/:domain", "/"]}>
                                <AppBar
                                    push={history.push}
                                    openMenu={() =>
                                        this.setState({ menuOpened: true })} />
                                <Drawer
                                    open={menuOpened}
                                    openMenu={open => this.setState({ menuOpened: open })}
                                    onLogout={() => onLogout(history.push)}
                                    params={match.params}
                                    location={location}
                                    history={history} />
                                    <Switch>
                                        {login.route()}
                                        <Route path={["/:domain/:app", "/:domain", "/"]}>
                                            <Home />
                                        </Route>
                                        <Route key="not-found">
                                            <NotFound />
                                        </Route>
                                    </Switch>
                            </Route>
                        </Switch>
                    </div> : <ErrorDialog />}
                <containers.Notification />
                <containers.Version />
            </React.Fragment>
        )
    }
}

const ConnectedApp = connect(
    state => ({...state.app}), {
        checkSession: (history, location, callback) =>
            login.actions.session(
                history, location, callback,
            ),
        sendError: (error, cause, location, version) => ({
            type: ERROR_REPORT.action,
            [API_CALL]: {
                request: {
                    url: '/accounts/report',
                    body: {
                        error,
                        cause,
                        location,
                        version,
                    },
                },
            },
        }),
        onLogout: push => login.actions.logout(push),
    }
)(App)

export const route = () =>
    <Route path="/" component={ConnectedApp} />

export default ConnectedApp
