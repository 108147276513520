import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'
import { IconButton, Badge, Dialog, Button, Icon } from 'components'
import { AppBar, Toolbar, Transition, Alert } from 'components'
import { LinearProgress } from 'components'
import { ActionShoppingCart, ActionClear } from 'components/icons'
import { Stepper, Step, steps } from './steps'
import { useCart, useAuthenticated, useDisplay } from './hooks'
import {
    LOGIN, CART_LOAD, CART_EMPTY, CART_STEP, CART_UPDATE, CART_ADD,
    PAYMENT_METHODS, PAYMENT_METHOD_UPDATE, PAYMENT_METHOD_REMOVE,
    PAYMENT_CONFIRM, PAYMENT_CONFIRM_CARD, API_CALL, PAYMENT_VALIDATE,
    DISPLAY_NOTIFICATION, ACCEPT_CGV, REDEEM,
} from 'actions'
import css from './index.module.css'

const init = {
    items: [],
    methods: [],
    amount: 0,
    step: 'basket',
    today: new Date(),
    cgv: false,
    cgvDate: null,
    confirmCvc: false,
    addCard: false,
    bill: null,
    renewal: false,
    updating: false,
    confirmation: null,
    validation: false,
    error: undefined,
    due_date: null,
    readonly: false,
}

export const reducer = (state=init, action) => {
    switch(action.type) {
        case LOGIN.SUCCESS:
            return {
                ...state,
                ...action.payload.cart || {},
            }
        case CART_STEP:
            return {
                ...state,
                step: action.step,
            }
        case CART_EMPTY:
            return init
        case CART_LOAD.REQUEST:
        case REDEEM.REQUEST:
        case PAYMENT_METHODS.REQUEST:
        case PAYMENT_CONFIRM.REQUEST:
        case CART_UPDATE.REQUEST:
            return {
                ...state,
                updating: true,
                error: undefined,
            }
        case CART_LOAD.SUCCESS:
        case CART_UPDATE.SUCCESS:
        case CART_ADD.SUCCESS:
        case REDEEM.SUCCESS:
            if (action.payload.cart) {
                return {
                    ...state,
                    updating: false,
                    items: [...action.payload.cart.items],
                    amount: action.payload.cart.amount,
                    step: action.payload.cart.step,
                    today: action.payload.cart.today,
                    bill: action.payload.cart.bill,
                    renewal: action.payload.cart.renewal,
                }
            } else if (action.payload.bill) {
                return {
                    ...state,
                    updating: false,
                    bill: action.payload.bill,
                }
            } else {
                return {
                    ...state,
                    updating: false,
                }
            }
        case PAYMENT_VALIDATE:
            return {
                ...state,
                validation: action.validation,
                confirmation: action.confirmation ? {...state.confirmation, ...action.confirmation} : action.confirmation,
                updating: action.validation || ( action.confirmation && action.confirmation.id === 'new_card' ),
                addCard: action.confirmation && action.confirmation.id === 'new_card',
                error: action.error,
                methods: action.remove ? state.methods.filter(
                    method => method.id !== action.remove,
                ) : state.methods,
            }
        case PAYMENT_METHODS.SUCCESS:
            return {
                ...state,
                methods: action.payload.methods,
                updating: false,
            }
        case ACCEPT_CGV:
            return {
                ...state,
                cgv: action.accepted,
                cgvDate: action.accepted ? new Date() : null,
            }
        case PAYMENT_CONFIRM_CARD:
            return {
                ...state,
                confirmCvc: action.confirm,
                updating: false,
            }
        case PAYMENT_METHOD_REMOVE.SUCCESS:
            return {
                ...state,
                methods: state.methods.filter(
                    method => method.id !== action.payload.removed,
                ),
            }
        case PAYMENT_METHOD_UPDATE:
            return {
                ...state,
                methods:
                    state.methods.map(method =>
                        method.id === action.method ?
                        {...method, selected: true } :
                        {...method, selected: false }
                    ),
            }
        case PAYMENT_CONFIRM.SUCCESS:
            if (action.payload.needs_confirmation) {
                return state
            } else {
                return {
                    ...init,
                    ...action.payload.cart,
                }
            }
        default:
            return state
    }
}

export const CartButton = () => {
    const cart = useCart()
    const history = useHistory()
    return !!cart && !!cart.items && cart.items.length > 0 &&
        <IconButton
            color="primary"
            onClick={() => history.push('/cart')}>
            <Badge
                color="secondary"
                badgeContent={cart.items.length}
                classes={{colorSecondary: css.cartBadge}}>
                <ActionShoppingCart />
            </Badge>
        </IconButton>
}

const useActions = () => {
    const dispatch = useDispatch()
    return {
        confirmCvc: useCallback(confirm => {
            dispatch({
                type: PAYMENT_CONFIRM_CARD,
                confirm,
            })
        }, [dispatch]),
        validatePayment: useCallback(confirmation =>
            dispatch({
                type: PAYMENT_VALIDATE,
                validation: confirmation.id !== 'new_card',
                confirmation,
                error: undefined,
                remove: undefined,
            }), [dispatch]),
        confirmRedeem: useCallback(
            (body, onsuccess, onfailure) => dispatch({
                type: REDEEM.action,
                [API_CALL]: {
                    request: {
                        url: '/billing/redeem',
                        body,
                    },
                    onsuccess,
                    onfailure,
                }
            }), [dispatch]),
        emptyCart: useCallback(() => dispatch({
            type: CART_EMPTY,
        }), [dispatch]),
        error: useCallback(message => dispatch({
            type: DISPLAY_NOTIFICATION,
            message,
            severity: 'error',
        }), [dispatch]),
        changeStep: useCallback(step => dispatch({
            type: CART_STEP,
            step,
        }), [dispatch])
    }
}

export const Cart = ({ renewal=false }) => {
    const cart = useCart()
    const display = useDisplay()
    const authenticated = useAuthenticated()
    const actions = useActions()
    const history = useHistory()
    const dispatch = useDispatch()
    const [ loading, setLoading ] = useState(true)
    const [ disabled, setDisabled ] = useState(cart.items.length === 0)
    useEffect(() => {
        let isCancelled = false
        authenticated && dispatch({
            type: CART_LOAD.action,
            [API_CALL]: {
                request: {
                    url: '/billing/cart/get',
                    body: { renewal },
                },
                onsuccess: ({ payload }) => {
                    !isCancelled && setDisabled(payload.cart.items.length === 0)
                    if (payload.cart.items.length === 0
                        && payload.cart.step !== 'validation')
                    {
                        dispatch({
                            type: DISPLAY_NOTIFICATION,
                            message: "Votre panier est vide",
                            severity: "info",
                        })
                        history.push('/')
                    } else {
                        !isCancelled && setLoading(false)
                    }
                },
                onfailure: () => {
                    dispatch({
                        type: DISPLAY_NOTIFICATION,
                        message: "Impossible de charger votre panier",
                        severity: "warning",
                    })
                    history.push('/')
                }
            },
        })
        return () => { isCancelled = true }
    }, [dispatch, history, authenticated, renewal])
    return authenticated &&
    <Dialog
        open
        fullScreen
        dense={display === 'mobile'}
        TransitionComponent={Transition}
        actions={
            steps[cart.step] &&
            <div
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                }}>
            {cart.amount > 0 && cart.amount < 100 &&
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    marginBottom: '10px',
                }}>
                <Alert
                    severity="info">
                    Votre panier doit être d'un minimum de 1€
                </Alert>
            </div>}
            <Button
                label={steps[cart.step].action(cart.amount)}
                color={"secondary"}
                raised
                fullWidth
                disabled={
                    cart.updating || disabled ||
                    ( 0 < cart.amount && cart.amount < 100 ) || (
                        !cart.items.some(
                            item => item.product.slug !== 'Redeem'
                        ) && cart.step === 'basket'
                    ) || ( cart.step === 'payment' && (
                        !cart.methods.some(
                            method => method.selected
                        )
                    ) )
                }
                startIcon={<Icon>{steps[cart.step].icon}</Icon>}
                onClick={() => {
                    steps[cart.step].click({
                        actions, cart, history, dispatch,
                    })
                }}
                {...steps[cart.step].overrides}
                />
            </div>
        }>
        <AppBar
            position="relative"
            color="transparent"
            classes={{colorTransparent: css.bar}}>
            <Toolbar>
                {( cart.step === 'basket' || cart.step === 'payment' ) &&
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={
                        () => history.push('/')
                    }>
                    <ActionClear />
                </IconButton>}
                <Stepper
                    display={display}
                    step={cart.step}
                    style={{flexGrow: 1}}
                    classes={{root: css.bar}} />
            </Toolbar>
        </AppBar>
        {loading ?
        <div style={{
                display: 'flex',
                top: '10%',
                position: 'absolute',
                margin: '10%',
                width: '80%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
            <div style={{
                    textAlign: 'center',
                }}>Chargement...</div>
            <div style={{
                    padding: '5px',
                    width: '100%',
                    height: '5px',
                }}>
                <LinearProgress />
            </div>
        </div> :
        <Step
            step={cart.step}
            updating={cart.updating}
            setDisabled={setDisabled}
            actions={actions} />}
    </Dialog>
}

export const CartRoute = () => [
        <Route
            key="renew"
            path="/renew">
            <Cart renewal />
        </Route>,
        <Route
            key="cart"
            path="/cart">
            <Cart />
        </Route>
    ]
