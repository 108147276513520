import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import QrCode from 'qrcode'
import { Dialog, Transition, AppBar, Toolbar, IconButton, Typography, LinearProgress, Button } from 'components'
import { ActionClear } from 'components/icons'
import { BlobProvider, Page, Font, View, Text, Image, Document, StyleSheet, Link } from '@react-pdf/renderer'
import { API_CALL, BILL_RECEIVE, DISPLAY_NOTIFICATION } from 'actions'
import colors from 'components/colors'

Font.register({
    family: 'Roboto',
    fonts: [
        { src: `${process.env.PUBLIC_URL}/fonts/Roboto-Regular.ttf` },
        { src: `${process.env.PUBLIC_URL}/fonts/Roboto-Bold.ttf`, fontWeight: 'bold' },
        { src: `${process.env.PUBLIC_URL}/fonts/Roboto-Italic.ttf`, fontStyle: 'italic' },
    ],
});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        fontSize: '11pt',
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'block',
        opacity: '0.3',
    },
    header: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingTop: '55pt',
        paddingLeft: '10pt',
    },
    company: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '10pt',
    },
    logo: {
        width: '36pt',
        height: '36pt',
    },
    qrcode: {
        width: '50pt',
        height: '50pt',
    },
    qrcodeHolder: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        alignItems: 'right',
        width: '100%',
        position: 'absolute',
        top: '0pt',
        padding: '10pt 15pt',
    },
    logoHolder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoBorder: {
        paddingRight: '10pt',
        borderRight: '1pt solid #f22c2c',
        marginRight: '10pt',
    },
    companyDetails: {
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
        padding: '5pt',
    },
    companyCommercialName: {
        display: 'block',
        color: '#2775d5',
        fontWeight: 'bold',
    },
    identifier: {
        color: colors.greyA400,
    },
    companyRealName: {
        display: 'block',
        color: colors.greyA400,
    },
    companyAddress: {
        display: 'block',
    },
    companyIdentification: {
        display: 'block',
    },
    leftText: {
        fontWeight: 'bold',
        paddingRight: '10pt',
    },
    client: {
        textAlign: 'left',
        borderLeft: '1pt solid #fad029',
        paddingLeft: '15pt',
    },
    companyHolder: {
        flexGrow: '1',
    },
    testmode: {
        position: 'absolute',
        display: 'flex',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        color: 'red',
        opacity: '0.4',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        transform: 'rotate(-55deg)',
        fontSize: '80pt',
        fontWeight: 'bold',
    }
})

const Address = ({ logo, left, infos, children, style={} }) =>
    <View style={[styles.company, ...style.root?[style.root]:[]]}>
        {(!!logo || !!left) &&
            <View style={[styles.logoHolder, ...!!logo?[styles.logoBorder]:[]]}>
                {!!logo &&
                    <Image
                        style={styles.logo}
                        src={logo} />}
                {!!left &&
                    <Text style={styles.leftText}>
                        {left}
                    </Text>}
            </View>}
        <View style={[styles.companyDetails, ...style.details?[style.details]:[]]}>
            {!!infos.company && infos.company.length > 0 &&
                <Text style={styles.companyCommercialName}>
                    {infos.company}
                </Text>}
            {(infos.display_name === true || !infos.company) &&
                <Text style={styles.companyRealName}>
                    {infos.name}
                </Text>}
            <Text style={styles.companyAddress}>
                {infos.first_line}
            </Text>
            {!!infos.second_line && infos.second_line.length > 0 &&
                <Text style={styles.companyAddress}>
                    {infos.second_line}
                </Text>}
            <Text style={styles.companyAddress}>
                {infos.zipcode} <Text style={{textTransform: 'uppercase'}}>{infos.town}</Text>
            </Text>
            {!!infos.siret && infos.siret.length > 0 &&
                <Text style={styles.companyIdentification}>
                    SIRET <Text style={styles.identifier}>{infos.siret}</Text>
                </Text>}
            {!!infos.rcs && infos.rcs.length > 0 &&
                <Text style={styles.companyIdentification}>
                    RCS <Text style={styles.identifier}>{infos.rcs}</Text>
                </Text>}
            {children}
        </View>
    </View>

const Details = ({ reference, isBill, bc, creationDate, billingDate, paymentMethod }) =>
    <View
        style={{
            display: "flex",
            flexDirection: "column",
            marginRight: '10pt',
        }}>
        <Text
            style={{
                fontSize: "24pt",
                fontWeight: "bold",
                color: '#f22c2c',
                opacity: '0.85',
            }}>
            {isBill ? "Facture" : "Bon de commande"}
        </Text>
        <View
            style={{
                display: "flex",
                flexDirection: "row",
            }}>
            <View
                style={{
                    display: "flex",
                    flexDirection: "column",
                    color: colors.grey800,
                }}>
                {isBill &&
                    <Text>
                        Numéro de facture&nbsp;
                    </Text>}
                <Text>
                    {isBill ? "Bon de commande" : "Référence"}&nbsp;
                </Text>
                <Text>
                    Date d'édition&nbsp;
                </Text>
                {isBill ?
                    <Text>
                        Date de règlement&nbsp;
                    </Text> :
                    <Text style={{
                            fontWeight: "bold",
                            color: "red",
                        }}>
                        Date limite de paiement&nbsp;
                    </Text>}
                {paymentMethod.length > 0 &&
                    <Text>
                        Moyen de paiement&nbsp;
                    </Text>}
            </View>
            <View
                style={{
                    display: "flex",
                    flexDirection: "column",
                    fontWeight: "bold",
                    marginRight: "10pt",
                }}>
                {isBill &&
                    <Text>
                        &nbsp;{reference}
                    </Text>}
                <Text>
                    &nbsp;{bc}
                </Text>
                <Text>
                    &nbsp;{creationDate}
                </Text>
                <Text style={isBill ? {} : {
                        color: "red",
                    }}>
                    &nbsp;{billingDate}
                </Text>
                {paymentMethod.length > 0 &&
                    <Text>
                        &nbsp;{paymentMethod}
                    </Text>}
            </View>
        </View>
    </View>

const productStyles = StyleSheet.create({
    root: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginTop: '10pt',
    },
    headers: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: `1pt solid ${colors.greyA400}`,
        padding: '5pt 10pt',
    },
    header: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    footer: {
        width: '100%',
        borderTop: `1pt solid ${colors.greyA400}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    rows: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'transparent',
        padding: '5pt 10pt',
    },
    firstColumn: {
        width: '60%',
        textAlign: 'left',
    },
    secondColumn: {
        width: '7%',
    },
    thirdColumn: {
        width: '18%',
    },
    fourthColumn: {
        width: '15%',
        textAlign: 'right',
    },
    totalLabel: {
        fontWeight: 'bold',
        backgroundColor: colors.grey300,
        padding: '10pt',
    },
    totalAmount: {
        fontWeight: 'bold',
        backgroundColor: colors.grey300,
        padding: '10pt',
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        width: '100%',
        textAlign: 'left',
    },
    subtitle: {
        width: '100%',
        fontSize: '10pt',
        fontStyle: 'italic',
        textAlign: 'left',
        color: colors.grey500,
    },
    amount: {
        color: colors.greyA200,
        textAlign: 'center',
    },
    price: {
        color: colors.greyA200,
        textAlign: 'center',
    },
    rowTotal: {
        fontWeight: 'bold',
    },
    even: {
        backgroundColor: 'white',
    },
    odd: {
        backgroundColor: colors.grey100,
    }
})

const fmt = new Intl.NumberFormat("fr-FR", {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}).format

const Products = ({ products }) => {
    const styles = productStyles
    return (
        <View style={styles.root}>
            <View style={styles.headers}>
                <Text style={[styles.header, styles.firstColumn]}>
                    Description
                </Text>
                <Text style={[styles.header, styles.secondColumn]}>
                    Qté
                </Text>
                <Text style={[styles.header, styles.thirdColumn]}>
                    Prix unitaire
                </Text>
                <Text style={[styles.header, styles.fourthColumn]}>
                    Montant
                </Text>
            </View>
            {products.map((product, row) =>
                <View
                    key={`row-${row}`}
                    style={[
                        styles.rows,
                        row % 2 === 0 ?
                        styles.even :
                        styles.odd
                    ]}>
                    <View style={[styles.firstColumn, styles.description]}>
                        <Text style={styles.label}>
                            {product.label}
                        </Text>
                        <Text style={styles.subtitle}>
                            {product.subtitle}
                        </Text>
                    </View>
                    <Text style={[styles.secondColumn, styles.amount]}>
                        {product.quantity}
                    </Text>
                    <Text style={[styles.thirdColumn, styles.price]}>
                        {fmt(product.price)}
                    </Text>
                    <Text style={[styles.fourthColumn, styles.rowTotal]}>
                        {fmt(product.amount / 100)}
                    </Text>
                </View>)}
        </View>
    )
}

const Outlays = ({ outlays, estimate }) => {
    const styles = productStyles
    return (
        <View style={styles.root}>
            <View style={styles.headers}>
                <Text style={[styles.header, styles.firstColumn]}>
                    {estimate ? "Description" : "Frais de Débours"}
                </Text>
                <Text style={[styles.header, styles.secondColumn]}>
                    {' '}
                </Text>
                <Text style={[styles.header, styles.thirdColumn]}>
                    {estimate ? "N° de devis" : "Facture"}
                </Text>
                <Text style={[styles.header, styles.fourthColumn]}>
                    Montant
                </Text>
            </View>
            {outlays.map((outlay, row) =>
                <View
                    key={`row-${row}`}
                    style={[
                        styles.rows,
                        row % 2 === 0 ?
                        styles.even :
                        styles.odd
                    ]}>
                    <View style={[styles.firstColumn, styles.description]}>
                        <Text style={styles.label}>
                            {outlay.label}
                        </Text>
                        <Text style={styles.subtitle}>
                            {outlay.subtitle}
                        </Text>
                    </View>
                    <Text style={[styles.secondColumn, styles.amount]}>
                        {' '}
                    </Text>
                    <Link style={[styles.thirdColumn]} src={`${window.location.origin}/account/outlay/${outlay.reference}`}>
                        {outlay.reference}
                    </Link>
                    <Text style={[styles.fourthColumn, styles.rowTotal]}>
                        {fmt(outlay.amount / 100)}
                    </Text>
                </View>)}
        </View>
    )
}

const footerStyles = StyleSheet.create({
    root: {
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderTop: `1pt solid ${colors.grey500}`,
        margin: '0 5%',
        position: 'absolute',
        bottom: '0',
    },
    mention: {
        padding: '10pt',
        textAlign: 'center',
        color: colors.grey500,
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        color: colors.grey500,
        justifyContent: 'flex-end',
        width: '100%',
        padding: '10 0',
    },
})

const Total = ({ template, products, outlays, isBill }) => {
    const styles = productStyles
    const { mention } = template
    const total = products.concat(outlays).reduce(
        (total, item) =>
            total + item.amount,
        0) / 100
    return (
        <View style={styles.root}>
            <View style={footerStyles.mention}>
                <Text>
                    {mention}
                </Text>
            </View>
            <View style={styles.footer}>
                <Text style={[styles.totalLabel, styles.thirdColumn]}>
                    {isBill ? total < 0 ? "Total crédité" : "Total TTC" : "À régler TTC"}
                </Text>
                <Text style={[styles.totalAmount, styles.fourthColumn]}>
                    {fmt(total)}
                </Text>
            </View>
        </View>
    )
}

const Footer = ({ reference, template, isBill, bc }) => {
    const styles = footerStyles
    const { contact } = template
    return (
        <View style={styles.root}>
            <View
                style={styles.mention}>
                <Text style={{
                        fontWeight: 'bold',
                        paddingTop: '10pt',
                    }}>
                    Des questions sur votre {isBill ? "facture" : "devis"} ?&nbsp;
                </Text>
                <Text>
                    Contactez-nous à l'adresse <Link src={`mailto:${contact.email}`}>{contact.email}</Link> ou par téléphone au <Link src={`tel:${contact.phone}`}>{contact.phone}</Link>.
                </Text>
            </View>
            <View style={styles.footer}>
                <Text>
                    {isBill ? reference : bc}&nbsp;-&nbsp;
                </Text>
                <Text
                    render={
                        ({ pageNumber, totalPages }) =>
                            `Page ${pageNumber} / ${totalPages}`
                    } />
            </View>
        </View>
    )
}

const BillPdf = ({ reference, isBill, productionMode, billingDate, creationDate, billingInfo, bc, paymentMethod, qrcode, rib, products=[], outlays=[], estimates=[] }) =>
    <Document>
        <Page size="A4" style={styles.page} wrap={false}>
            <Image
                style={styles.background}
                src={`${process.env.PUBLIC_URL}/img/bill-background.png`} />
            <View style={styles.header}>
                <Address
                    style={{root: styles.companyHolder}}
                    logo={`${process.env.PUBLIC_URL}/img/android-chrome-512x512.png`}
                    infos={billingInfo.template.address}>
                    <Text style={styles.companyIdentification}>
                        SIRET <Text style={styles.identifier}>{billingInfo.template.siret}</Text>
                    </Text>
                    <Text style={styles.companyIdentification}>
                        RCS <Text style={styles.identifier}>{billingInfo.template.rcs}</Text>
                    </Text>
                </Address>
                <Details
                    isBill={isBill}
                    reference={reference}
                    creationDate={creationDate}
                    billingDate={billingDate}
                    paymentMethod={paymentMethod}
                    bc={bc} />
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    paddingLeft: '10pt',
                }}>
                <Address
                    left={"À"}
                    style={{
                        details: styles.client,
                        root: {
                            marginTop: '10pt',
                            paddingLeft: '39pt',
                        }}}
                    infos={billingInfo.client} />
            </View>
            {products.length > 0 &&
                <Products
                    products={products} />}
            {estimates.length > 0 &&
                <Outlays
                    outlays={estimates} estimate />}
            {outlays.length > 0 &&
                <Outlays
                    outlays={outlays} />}
            <Total
                isBill={isBill}
                template={billingInfo.template}
                products={products}
                outlays={outlays.concat(estimates)} />
            <View style={styles.qrcodeHolder}>
                <Image
                    style={styles.qrcode}
                    src={qrcode} />
            </View>
            {!productionMode &&
                <View style={styles.testmode}>
                    <Text>DONN&Eacute;ES DE TEST</Text>
                </View>}
            {!!rib &&
                <View style={{
                    border: `2pt dashed ${colors.grey500}`,
                    display: "flex",
                    flexDirection: "column",
                    width: "10cm",
                    marginTop: '39pt',
                    marginLeft: '10%',
                    padding: '10pt',
                }}>
                    <View style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}>
                        <Text style={{
                            fontWeight: 'bold'
                        }}>Modalités de paiement : </Text>
                        <Text>Solde à la commande</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}>
                        <Text style={{
                            fontWeight: 'bold'
                        }}>Titulaire du compte : </Text>
                        <Text>{rib.owner}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}>
                        <Text style={{
                            fontWeight: 'bold'
                        }}>IBAN : </Text>
                        <Text>{rib.iban}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}>
                        <Text style={{
                            fontWeight: 'bold'
                        }}>BIC : </Text>
                        <Text>{rib.bic}</Text>
                    </View>
                </View>}
            <Footer
                isBill={isBill}
                reference={reference}
                bc={bc}
                template={billingInfo.template} />
        </Page>
    </Document>

const init = {
    products: [],
}

export const reducer = (state=init, action) => {
    switch (action.type) {
        case BILL_RECEIVE.SUCCESS:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}

class Bill extends React.Component {
    state = { loading: true, qrcode: null, disabled: false }

    constructor(props) {
        super(props)
        this.downloadLink = React.createRef()
        this.timeout = null
    }

    componentDidMount() {
        const { match: { params }, loadBill } = this.props
        loadBill(params.bill, ({ payload }) => {
            QrCode.toDataURL(payload.salt, {
                errorCorrectionLevel: 'H',
                scale: 1,
                margin: 1,
                color: {
                    dark: "#00000099",
                }
            }, (err, qrcode) => {
                this.setState({
                    loading: false,
                    qrcode,
                })
            })
        })
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout)
            this.timeout = null
        }
    }
    render() {
        const { loading, qrcode, disabled } = this.state
        const {
            history, products, outlays, estimates,
            reference, creation_date,
            billing_info, payment_date,
            notify, payment_method,
            bc, production, isBill, rib
        } = this.props
        const fmt =  date =>
            new Intl.DateTimeFormat("fr-FR", {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
            }).format(new Date(date))
        const Pdf =
            !loading &&
                <BillPdf
                    reference={reference}
                    creationDate={fmt(creation_date)}
                    billingDate={fmt(payment_date)}
                    billingInfo={billing_info}
                    products={products}
                    outlays={outlays}
                    estimates={estimates}
                    bc={bc}
                    isBill={isBill}
                    paymentMethod={payment_method}
                    qrcode={qrcode}
                    productionMode={production}
                    rib={rib} />
        const Loading =
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    width: '94%',
                    margin: '0px 3%',
                    minWidth: '300px',
                }}>
                <div style={{
                        textAlign: 'center',
                        paddingBottom: '10px',
                    }}>
                    Merci de patienter pendant le chargement de votre document...
                </div>
                <LinearProgress
                    style={{
                        height: '10px',
                        borderRadius: '5px'
                    }}
                    color="secondary" />
            </div>
        return (
            <Dialog
                fullScreen
                open
                TransitionComponent={Transition}
                dense>
                <div style={{
                        height:"calc(var(--vh, 1vh) * 100)",
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <AppBar
                        position="relative">
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={
                                    () => history.push('/account/bills')
                                }>
                                <ActionClear />
                            </IconButton>
                            <Typography variant="h6">
                                {loading ? "Chargement de votre document..." : isBill ? "Facture" : "Bon de commande"}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div
                        style={{
                            flexGrow: 1,
                        }}>
                        {loading ?
                            Loading :
                            <BlobProvider
                                document={Pdf}>
                                {({ blob, url, loading, error }) => {
                                    if (loading) {
                                        return Loading
                                    } else {
                                        return (
                                            <object
                                                type="application/pdf"
                                                data={url}
                                                name={"Facture-soeasyto-" + reference + ".pdf"}
                                                width="100%"
                                                height="100%">
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                    <a
                                                        ref={this.downloadLink}
                                                        style={{display: 'none'}}
                                                        href={url}
                                                        download={"Facture-soeasyto-" + reference + ".pdf"}>
                                                        {"Facture-soeasyto-" + reference + ".pdf"}
                                                    </a>
                                                    <Button
                                                        secondary
                                                        raised
                                                        disabled={disabled}
                                                        label="Télécharger"
                                                        onClick={
                                                            () => {
                                                                this.setState({ disabled: true })
                                                                this.downloadLink.current.click()
                                                                notify("Téléchargement de la facture démarré...")
                                                                window.URL.revokeObjectURL(url)
                                                                this.timeout = setTimeout(
                                                                    () => this.setState({ disabled: false }),
                                                                    2000,
                                                                )
                                                            }
                                                        } />
                                                </div>
                                            </object>
                                        )
                                    }
                                }}
                            </BlobProvider>}
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default connect(
    state => ({ ...state.account.bill }), {
        loadBill: (ref, onsuccess) => ({
            type: BILL_RECEIVE.action,
            [API_CALL]: {
                request: {
                    url: `/billing/get/${ref}`,
                },
                onsuccess,
            },
        }),
        notify: message => ({
            type: DISPLAY_NOTIFICATION,
            message,
        })
    }
)(withRouter(Bill))
